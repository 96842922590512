<template>
  <div class="organisation">
    <p>JDoodle for your organisation</p>
    <OrganisationItem v-for="item in items" :key="item.id" :item="item" />
    <a v-if="routerName === 'compilerAPI'" class="my_button" style="display: flex; width: fit-content;"
      href="https://app.monstercampaigns.com/c/eelbkirqhtobo1lclzwx/" target="_blank" rel="noopener noreferrer">Contact
      Us!</a>
    <button v-else @click="changeTouchButtonText">{{ touchButton }}</button>
  </div>
</template>
<script>
import OrganisationItem from './OrganisationItem.vue'

export default {
  name: 'organisation_block',
  data() {
    return {
      routerName: this.$route.name,
      touchButton: 'Get in touch',
      items: [
        {
          id: 1,
          img: 'organisation1.png',
          text:
            ' Looking for advanced IDE and compiler features such as debugging, GUI,DBs, etc.?'
        },
        {
          id: 2,
          img: 'organisation2.png',
          text:
            'We do provide white-labelled tailor-made state-of-the-art IDE and compiler solutions'
        }
      ]
    }
  },
  components: {
    OrganisationItem
  },
  methods: {
    changeTouchButtonText() {
      console.log(this.routerPath)
      this.touchButton = 'Please reach at hello@jdoodle.com'
    },
  }
}
</script>
<style lang="scss" scoped>
.organisation {
  padding: 8% 12% 8% 10%;
  background: #363739;

  >p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: #f8f9f8;
  }

  button,
  a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #f5f5f5;

    padding: 12px 20px;
    margin-top: 25px;
    background: #363739;
    border: 1px solid #ffffff;
    border-radius: 30px;
  }
}

@media only screen and (max-width: 1040px) {
  .organisation {
    padding: 6% 8% 6% 6%;

    >p {
      font-size: 33px;
      line-height: 43px;
    }

    button,
    a {
      font-size: 15px;
      line-height: 20px;
      color: #f5f5f5;

      padding: 10px 16px;
    }
  }
}

@media only screen and (max-width: 880px) {
  .organisation {
    >p {
      font-size: 28px;
      line-height: 38px;
    }
  }
}

@media only screen and (max-width: 630px) {
  .organisation {
    align-items: center;
    display: flex;
    flex-direction: column;

    >p {
      font-size: 26px;
      line-height: 36px;
    }

    button,
    a {
      font-size: 14px;

      padding: 8px 14px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .organisation {
    padding: 36px 17px 50px 24px;

    >p {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      margin-bottom: 12px;
    }

    button,
    a {
      font-size: 16px;
      line-height: 20px;
      padding: 12px 20px;
    }
  }
}
</style>
