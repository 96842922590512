<template>
  <div class="organisation_item">
    <img :src="`${require('../../assets/images/' + item.img)}`" />

    <p>{{ item.text }}</p>
  </div>
</template>
<script>
export default {
  name: 'organisation_item',
  props: {
    item: {
      type: Object,
      require: true
    }
  }
}
</script>
<style lang="scss" scoped>
.organisation_item {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 40px;

  p {
    width: 65%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 141%;
    letter-spacing: 0.01em;

    color: #ffffff;
  }
}
@media only screen and (max-width: 1040px) {
  .organisation_item {
    gap: 25px;
    margin-top: 30px;
    p {
      font-size: 19px;
    }
  }
}
@media only screen and (max-width: 880px) {
  .organisation_item {
    gap: 23px;
    margin-top: 27px;
    img {
      width: 45px;
      height: 45px;
    }
    p {
      font-size: 17px;
      line-height: 129%;
    }
  }
}

@media only screen and (max-width: 630px) {
  .organisation_item {
    gap: 20px;
    margin-top: 24px;
    img {
      width: 40px;
      height: 40px;
    }
    p {
      font-size: 15px;
      line-height: 120%;
    }
  }
}
@media only screen and (max-width: 375px) {
  .organisation_item {
    gap: 17px;
    margin-top: 23px;
    img {
      width: 32px;
      height: 32px;
    }
    p {
      width: 85%;
      font-size: 14px;
      line-height: 141%;
    }
  }
}
</style>
