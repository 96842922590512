<template>
  <div class="download_block">
    <div class="download_items">
      <div class="download_img">
        <a
          href="https://apps.apple.com/us/app/jdoodle/id1544598494"
          target="_blank"
        >
          <img src="../../assets/images/app_store.png" alt="app_store" />
        </a>
      </div>
      <div class="download_img">
        <a
          href="https://play.google.com/store/apps/details?id=com.nutpan.jdoodle_app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
        >
          <img src="../../assets/images/google_play.png" alt="google_play" />
        </a>
      </div>
    </div>
    <div class="download_now">
      <p class="download_title">Download now!</p>
      <p class="download_text">
        Using our online IDEs, you can write, compile and test programs on any
        platform
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'download_block'
}
</script>
<style lang="scss" scoped>
.download_block {
  display: flex;
  padding: 150px 0;
  justify-content: center;
  align-items: center;
  .download_items {
    display: flex;
    gap: 12px;
    padding-right: 70px;
    border-right: 1px solid #000000;
    .download_img {
      display: flex;
      a {
        img {
          width: 100%;
        }
      }
    }
  }
  .download_now {
    padding-left: 70px;
    width: 21%;
    .download_title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;

      color: #363739;
    }
    .download_text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 124.8%;
      color: #000000;
    }
  }
}

@media only screen and (max-width: 1040px) {
  .download_block {
    padding: 120px 0;
    .download_items {
      gap: 12px;
      padding-right: 40px;
    }
    .download_now {
      padding-left: 40px;
      width: 30%;
      .download_title {
        font-size: 22px;
      }
    }
  }
}
@media only screen and (max-width: 880px) {
  .download_block {
    padding: 80px 0;
    .download_items {
      padding-right: 30px;
    }
    .download_now {
      padding-left: 30px;
      width: 32%;
      .download_title {
        font-size: 21px;
      }
      .download_text {
        font-size: 13px;
      }
    }
  }
}
@media only screen and (max-width: 630px) {
  .download_block {
    flex-direction: column-reverse;
    align-items: center;
    padding: 30px 8% 30px 6%;
    .download_items {
      gap: 20px;
      padding-right: 0px;
      border: unset;
    }
    .download_now {
      padding-left: 0px;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;

      .download_text {
        margin-top: 5px;
      }
    }
  }
}
@media only screen and (max-width: 375px) {
}
</style>
